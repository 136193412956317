import Vue from "vue";
import VueRouter from "vue-router";
import Index from "@/views/Index.vue";
import VueAnalytics from "vue-analytics";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "/",
        name: "IndexLT",
        component: Index,
      },
    ],
  },
  {
    path: "/pl",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "/",
        name: "IndexPL",
        component: Index,
      },
    ],
  },
  {
    path: "*",
    name: "Error",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/Error.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

Vue.use(VueAnalytics, {
  id: "UA-179227206-1",
  router,
});

export default router;
