<template>
<div class="leading-relaxed">
    <Hero id="top" />
    <Goal id="goal" />
    <Action />
    <Contact />
    <Partners />
</div>
</template>

<script>
export default {
    name: "Home",
    components: {
        Hero: () => import( /* webpackChunkName: "hero" */ "@/components/Hero.vue"),
        Goal: () => import( /* webpackChunkName: "goal" */ "@/components/Goal.vue"),
        Action: () =>
            import( /* webpackChunkName: "action" */ "@/components/Action.vue"),
        Contact: () =>
            import( /* webpackChunkName: "contact" */ "@/components/Contact.vue"),
        Partners: () =>
            import( /* webpackChunkName: "partners" */ "@/components/Partners.vue"),
    },
    metaInfo() {
        return {
            title: this.$t("title"),
            meta: [{
                    name: "description",
                    content: this.$t("description"),
                },
                {
                    name: "keywords",
                    content: this.$t("keywords"),
                },
                {
                    property: "og:url",
                    content: "https://versekosasociacija.lt" + this.$route.path,
                },
                {
                    property: "og:title",
                    content: this.$t("title"),
                },
                {
                    property: "og:description",
                    content: this.$t("description"),
                },
                {
                    property: "og:image",
                    content: "https://versekosasociacija.lt" + require("@/assets/img/fb.jpg"),
                },
            ],
        };
    },
};
</script>
