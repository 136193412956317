<template>
<div class="fixed top-0 w-full z-50" :class="
      showNavbar
        ? 'animate__animated animate__fadeInDown py-2 bg-blue-700'
        : 'py-5'
    ">
    <div class="container mx-auto flex items-center justify-between px-8">
        <div class="font-semibold pr-8">
            <a href="#" v-scroll-to="'#top'" v-html="$t('title')" class="text-white"></a>
        </div>
        <div class="py-5">
            <router-link to="/" class="px-3 text-white hover:text-red-500" :class="{ 'font-bold text-red-500': $route.path == '/' }">LT</router-link>
            <router-link to="/pl" class="px-3 text-white hover:text-red-500" :class="{ 'font-bold text-red-500': $route.path == '/pl' }">PL</router-link>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "Navbar",
    data() {
        return {
            showNavbar: false,
        };
    },
    mounted() {
        window.addEventListener("scroll", this.onScroll);
    },
    methods: {
        onScroll() {
            this.showNavbar = window.scrollY > 100;
        },
    },
};
</script>
