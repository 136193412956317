import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from './i18n';

import "./plugins/vue-meta";
import "./plugins/vue-scrollto";


Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  let language = "lt";
  if (to.path == "/pl") {
    language = "pl";
  }

  i18n.locale = language;
  next();
});

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
