<template>
  <div id="app">
    <Navbar />
    <transition
      :duration="{ enter: 600, leave: 600 }"
      name="fade"
      mode="out-in"
      enter-active-class="animate__animated animate__fadeIn animate__slow"
      leave-active-class="animate__animated animate__fadeOut"
    >
      <router-view />
    </transition>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";

export default {
  name: "App",
  components: {
    Navbar,
  },
};
</script>

<style lang="scss" src="@/assets/scss/app.scss"></style>
